import React, { useEffect } from 'react';
import {
  Redirect, Route, Switch, useLocation,
} from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import HeaderPlatform from '../components/HeaderPlatform';
import HeaderTeacherPlatform from '../components/HeaderTeacherPlatform';
import Navbar from '../components/Navbars/Navbar';
import NavbarStudent from '../components/Navbars/NavbarStudent';
import NavbarAdmin from '../components/Navbars/NavbarAdmin';
import NavbarSite from '../components/Navbars/NavbarSite';
import { useStores } from '../stores';
import { RegisterStoresProvider } from '../stores/registerUserContext';
import { FreeLessons } from '../containers/Free-Lessons';
import NavbarTeacher from '../components/Navbars/NavbarTeacher';

const HomeTeacher = React.lazy(async () => import('../containers/HomeTeacher').then((m) => ({ default: m.HomeTeacher })));
const HomeTeacherNew = React.lazy(async () => import('../containers/HomeTeacherNew').then((m) => ({ default: m.HomeTeacherNew })));
const HomeStudent = React.lazy(async () => import('../containers/HomeStudent').then((m) => ({ default: m.HomeStudent })));
const HomeStudentNew = React.lazy(async () => import('../containers/HomeStudentNew').then((m) => ({ default: m.HomeStudentNew })));

const RegisterStudentUniqueStep = React.lazy(async () => import('../containers/RegisterStudent/RegisterStudentUniqueStep').then((m) => ({ default: m.RegisterStudentUniqueStep })));

const RegisterTeacherStepOne = React.lazy(async () => import('../containers/RegisterTeacher/RegisterTeacherStepOne').then((m) => ({ default: m.RegisterTeacherStepOne })));
const RegisterTeacherStepTwo = React.lazy(async () => import('../containers/RegisterTeacher/RegisterTeacherStepTwo').then((m) => ({ default: m.RegisterTeacherStepTwo })));

const Login = React.lazy(async () => import('../containers/Login').then((m) => ({ default: m.Login })));
const Recovery = React.lazy(async () => import('../containers/Recovery').then((m) => ({ default: m.Recovery })));
const PasswordChange = React.lazy(async () => import('../containers/PasswordChange').then((m) => ({ default: m.PasswordChange })));
const HomeAdmin = React.lazy(async () => import('../containers/HomeAdmin').then((m) => ({ default: m.HomeAdmin })));
const HomeAdminUsers = React.lazy(async () => import('../containers/HomeAdminUsers').then((m) => ({ default: m.HomeAdminUsers })));
const Page404 = React.lazy(async () => import('../containers/Page404').then((m) => ({ default: m.Page404 })));
const ProfileTeacher = React.lazy(async () => import('../containers/ProfileTeacher').then((m) => ({ default: m.ProfileTeacher })));
const ProfileStudent = React.lazy(async () => import('../containers/ProfileStudent').then((m) => ({ default: m.ProfileStudent })));
const ProfileAdmin = React.lazy(async () => import('../containers/ProfileAdmin').then((m) => ({ default: m.ProfileAdmin })));
const History = React.lazy(async () => import('../containers/History').then((m) => ({ default: m.History })));
const StudentsList = React.lazy(async () => import('../containers/StudentsList').then((m) => ({ default: m.StudentsList })));
const TeacherInfo = React.lazy(async () => import('../containers/TeacherInfo').then((m) => ({ default: m.TeacherInfo })));
const TeacherFeedbacks = React.lazy(async () => import('../containers/TeacherFeedbacks').then((m) => ({ default: m.TeacherFeedbacks })));
const TeacherBackups = React.lazy(async () => import('../containers/Backup/Teacher').then((m) => ({ default: m.TeacherBackupRequests })));
const StudentBackups = React.lazy(async () => import('../containers/Backup/Student').then((m) => ({ default: m.StudentBackupRequests })));
const UserDetailsPage = React.lazy(async () => import('../containers/UserDetailsPage').then((m) => ({ default: m.UserDetailsPage })));
const TeacherEditScorePage = React.lazy(async () => import('../containers/TeacherEditScorePage').then((m) => ({ default: m.TeacherEditScorePage })));
const Contact = React.lazy(async () => import('../containers/Contact').then((m) => ({ default: m.Contact })));
const StudentGuidelinesEditionPage = React.lazy(async () => import('../containers/StudentGuidelinesEditionPage').then((m) => ({ default: m.StudentGuidelinesEditionPage })));
const StudentGuidelinesPage = React.lazy(async () => import('../containers/StudentGuidelinesPage').then((m) => ({ default: m.StudentGuidelinesPage })));
const TeacherGuidelinesEditionPage = React.lazy(async () => import('../containers/TeacherGuidelinesEditionPage').then((m) => ({ default: m.TeacherGuidelinesEditionPage })));
const TeacherGuidelinesPage = React.lazy(async () => import('../containers/TeacherGuidelinesPage').then((m) => ({ default: m.TeacherGuidelinesPage })));
const HomeNew = React.lazy(async () => import('../containers/HomeNew').then((m) => ({ default: m.HomeNew })));
const ForTeachers = React.lazy(async () => import('../containers/ForTeachers').then((m) => ({ default: m.ForTeachers })));
const NotificationsCentral = React.lazy(async () => import('../containers/NotificationsCentral').then((m) => ({ default: m.NotificationsCentral })));
const ForStudents = React.lazy(async () => import('../containers/ForStudents').then((m) => ({ default: m.ForStudents })));
const Learning = React.lazy(async () => import('../containers/Learning').then((m) => ({ default: m.Learning })));
const Courses = React.lazy(async () => import('../containers/Courses').then((m) => ({ default: m.Courses })));
const Courses1 = React.lazy(async () => import('../containers/Courses1').then((m) => ({ default: m.Courses1 })));
const ContentArea = React.lazy(async () => import('../containers/Content-area').then((m) => ({ default: m.ContentArea })));
const FirstSteps = React.lazy(async () => import('../containers/First-steps').then((m) => ({ default: m.FirstSteps })));
const Engenharia = React.lazy(async () => import('../containers/Engenharia').then((m) => ({ default: m.Engenharia })));
const EngenhariaInt = React.lazy(async () => import('../containers/Engenharia-intermediario').then((m) => ({ default: m.EngenhariaInt })));
const EngenhariaAv = React.lazy(async () => import('../containers/Engenharia-avancado').then((m) => ({ default: m.EngenhariaAv })));
const Tecnologia = React.lazy(async () => import('../containers/Tecnologia').then((m) => ({ default: m.Tecnologia })));
const TecnologiaInt = React.lazy(async () => import('../containers/Tecnologia-intermediario').then((m) => ({ default: m.TecnologiaInt })));
const TecnologiaAv = React.lazy(async () => import('../containers/Tecnologia-avancado').then((m) => ({ default: m.TecnologiaAv })));
const Subscription = React.lazy(async () => import('../containers/NewSubscription').then((m) => ({ default: m.NewSubscription })));
const Ebooks = React.lazy(async () => import('../containers/Ebooks').then((m) => ({ default: m.Ebooks })));
const Tips = React.lazy(async () => import('../containers/Tips').then((m) => ({ default: m.Tips })));
const Grammar = React.lazy(async () => import('../containers/Grammar').then((m) => ({ default: m.Grammar })));
const Pronunciation = React.lazy(async () => import('../containers/Pronunciation').then((m) => ({ default: m.Pronunciation })));
const Curiosities = React.lazy(async () => import('../containers/Curiosities').then((m) => ({ default: m.Curiosities })));
const Dialogs = React.lazy(async () => import('../containers/Dialogs').then((m) => ({ default: m.Dialogs })));
const GuidedClasses = React.lazy(async () => import('../containers/GuidedClasses').then((m) => ({ default: m.GuidedClasses })));
const Companies = React.lazy(async () => import('../containers/Companies').then((m) => ({ default: m.Companies })));
const Coupons = React.lazy(async () => import('../containers/Coupons').then((m) => ({ default: m.Coupons })));
const DiscountLinks = React.lazy(async () => import('../containers/DiscountLinks').then((m) => ({ default: m.DiscountLinks })));
const AdminListOfUserWithCreditExpirationToday = React.lazy(async () => import('../containers/AdminListOfUserWithCreditExpirationToday').then((m) => ({ default: m.AdminListOfUserWithCreditExpirationToday })));
const ModalControlPage = React.lazy(async () => import('../containers/ModalControlPage').then((m) => ({ default: m.ModalControlPage })));
const ModerateCommentsPage = React.lazy(async () => import('../containers/ModerateComments').then((m) => ({ default: m.ModerateCommentsPage })));
const FreeTrial = React.lazy(async () => import('../containers/FreeTrial').then((m) => ({ default: m.FreeTrial })));
const PaymentScreen = React.lazy(async () => import('../containers/PaymentScreen').then((m) => ({ default: m.PaymentScreen })));
// const UnavailableBlog = React.lazy(async () =>
//  import('../containers/UnavailableBlog').then((m) => ({ default: m.UnavailableBlog })));

function ProtectedRoutes({ children }) {
  const { userStore: { token } } = useStores();
  return (
    <Route render={() => (token ? children : <Redirect to="/" />)} />
  );
}

function AccessForSomeUsers({
  redirectTo,
  onlyUsers,
  children,
}) {
  const { userStore: { userData } } = useStores();
  return (
    <Route render={() => (onlyUsers.includes(userData.userType)
      ? children
      : <Redirect to={redirectTo} />)}
    />
  );
}

export function Routes() {
  const { userStore: { userData } } = useStores();
  const location = useLocation();

  return (
    <>
      {userData ? (
        userData.userType === 'Teacher'
          ? (
            <>
              <HeaderTeacherPlatform />
              <NavbarTeacher />
            </>
          ) : (userData.userType === 'Student'
            ? (
              <>
                <HeaderPlatform />
                <NavbarStudent />
              </>
            ) : <NavbarAdmin />)
      ) : (!location.pathname.includes('/register') && !location.pathname.includes('/blog') && !location.pathname === '/') && <NavbarSite />}

      <Switch>
        {/* <Route path="/" exact component={HomeNew} />
       <Route path="/login" component={Login} /> */}
        <Route path="/" exact component={Login} />
        <Route path="/for-teachers" exact component={ForTeachers} />
        <Route path="/for-students" exact component={ForStudents} />
        <Route path="/recovery" component={Recovery} />
        <Route path="/recovery-password/:uuid" component={PasswordChange} />
        <Route path="/free-trial/:id" component={FreeTrial} />

        <ProtectedRoutes
          path={[
            '/home/student',
            '/home/student-new',
            '/home/teacher',
            '/home/admin',
          ]}
          exact
        >
          {
           ['/home/student'].includes(location.pathname) && (
             <AccessForSomeUsers
               redirectTo="/"
               onlyUsers={['Student']}
             >
               <Route path="/home/student" exact component={HomeStudentNew} />
             </AccessForSomeUsers>
           )
         }
          {
           ['/home/teacher'].includes(location.pathname) && (
             <AccessForSomeUsers
               redirectTo="/"
               onlyUsers={['Teacher']}
             >
               <Route path="/home/teacher" exact component={HomeTeacherNew} />
             </AccessForSomeUsers>
           )
         }
          {
           location.pathname === '/home/admin' && (
             <AccessForSomeUsers
               redirectTo="/"
               onlyUsers={['Admin', 'Super Admin']}
             >
               <Route path="/home/admin" exact component={HomeAdmin} />
             </AccessForSomeUsers>
           )
         }
        </ProtectedRoutes>

        <ProtectedRoutes
          path={[
            '/home/admin',
            '/home/admin/users',
            '/home/admin/backups/teacher',
            '/home/admin/backups/student',
            '/home/admin/companies',
            '/home/admin/coupons',
            '/home/admin/discount-links',
            '/home/admin/modal-controls',
            '/home/admin/moderate-coments',
          ]}
        >
          <Route path="/home/admin/users" exact component={HomeAdminUsers} />
          <Route path="/home/admin/users/details" exact component={UserDetailsPage} />
          <Route path="/home/admin/users/details/edit-score" exact component={TeacherEditScorePage} />
          <Route path="/home/admin/student-guidelines" component={StudentGuidelinesEditionPage} />
          <Route path="/home/admin/teacher-guidelines" component={TeacherGuidelinesEditionPage} />
          <Route path="/home/admin/backups/teacher" component={TeacherBackups} />
          <Route path="/home/admin/backups/student" component={StudentBackups} />
          <Route path="/home/admin/companies" component={Companies} />
          <Route path="/home/admin/coupons" component={Coupons} />
          <Route path="/home/admin/discount-links" component={DiscountLinks} />
          <Route path="/home/admin/user-expiration-today" component={AdminListOfUserWithCreditExpirationToday} />
          <Route path="/home/admin/modal-controls" component={ModalControlPage} />
          <Route path="/home/admin/moderate-comments" component={ModerateCommentsPage} />
        </ProtectedRoutes>

        <ProtectedRoutes
          path={[
            '/notifications',
          ]}
        >
          {(userData?.userType === 'Student' || userData?.userType === 'Teacher') ? (
            <AccessForSomeUsers
              redirectTo="/"
              onlyUsers={['Student', 'Teacher']}
            >
              <Route path="/notifications" component={NotificationsCentral} />
            </AccessForSomeUsers>
          ) : (
            <AccessForSomeUsers
              redirectTo="/"
              onlyUsers={['Admin', 'Super Admin']}
            >
              <Route path="/notifications" component={NotificationsCentral} />
            </AccessForSomeUsers>
          )}

        </ProtectedRoutes>

        <ProtectedRoutes
          path={[
            '/profile',
            '/profile/student',
            '/profile/teacher',
            '/profile/student/add-credits',
            '/profile/teacher/withdraw-credits',
            '/profile/teacher/edit-plan',
            '/profile/student/edit-plan',
          ]}
          exact
        >
          <Route path="/profile/student" component={ProfileStudent} />
          <Route path="/profile/teacher" component={ProfileTeacher} />
        </ProtectedRoutes>

        <ProtectedRoutes
          path={[
            '/subscription',
            '/payment',
            '/history',
            '/studentslist',
            '/student-guidelines',
            '/teacher-guidelines',
            '/contact',
            '/teacher-info',
            '/teacher-feedbacks',
          ]}
        >
          <Route path="/subscription" component={Subscription} />
          <Route path="/payment" component={PaymentScreen} />
          <Route path="/history" component={History} />
          <Route path="/studentslist" component={StudentsList} />
          <Route path="/student-guidelines" component={StudentGuidelinesPage} />
          <Route path="/teacher-guidelines" component={TeacherGuidelinesPage} />
          <Route path="/contact" component={Contact} />
          <Route path="/teacher-info" component={TeacherInfo} />
          <Route path="/teacher-feedbacks" component={TeacherFeedbacks} />
        </ProtectedRoutes>

        <ProtectedRoutes
          path={[
            '/platform',
            '/platform/learning',
            '/platform/courses',
            '/platform/courses1',
            '/platform/content-area',
            '/platform/ebooks',
            '/platform/free-lessons',
            '/platform/tips',
            '/platform/grammar',
            '/platform/pronunciation',
            '/platform/guided-classes',
            '/platform/curiosities',
            '/platform/dialogs',
            '/platform/first-steps',
            '/platform/engenharia',
            '/platform/engenharia-intermediario',
            '/platform/engenharia-avancado',
            '/platform/tecnologia',
            '/platform/tecnologia-intermediario',
            '/platform/tecnologia-avancado',
          ]}
        >
          <Route path="/platform/learning" component={Learning} />
          <Route path="/platform/courses" component={Courses} />
          <Route path="/platform/courses1" component={Courses1} />
          <Route path="/platform/content-area" component={ContentArea} />
          <Route path="/platform/first-steps" component={FirstSteps} />
          <Route path="/platform/engenharia" component={Engenharia} />
          <Route path="/platform/engenharia-intermediario" component={EngenhariaInt} />
          <Route path="/platform/engenharia-avancado" component={EngenhariaAv} />
          <Route path="/platform/tecnologia" component={Tecnologia} />
          <Route path="/platform/tecnologia-intermediario" component={TecnologiaInt} />
          <Route path="/platform/tecnologia-avancado" component={TecnologiaAv} />
          <Route path="/platform/e-books" component={Ebooks} />
          <Route path="/platform/free-lessons" component={FreeLessons} />
          <Route path="/platform/tips" component={Tips} />
          <Route path="/platform/grammar" component={Grammar} />
          <Route path="/platform/pronunciation" component={Pronunciation} />
          <Route path="/platform/guided-classes" component={GuidedClasses} />
          <Route path="/platform/curiosities" component={Curiosities} />
          <Route path="/platform/dialogs" component={Dialogs} />
        </ProtectedRoutes>

        <RegisterStoresProvider
          path={[
            '/register-student',
            '/register-student/step-one',
            '/register-student/step-two',
            '/register-student/step-three',
            '/register-student/step-four',
            '/register-teacher',
            '/register-teacher/step-one',
            '/register-teacher/step-two',
          ]}
          exact
        >
          <Route path="/register-student">
            <Route path="/register-student/step-one" component={RegisterStudentUniqueStep} />
          </Route>
          <Route path="/register-teacher">
            <Route path="/register-teacher/step-one" component={RegisterTeacherStepOne} />
            <Route path="/register-teacher/step-two" component={RegisterTeacherStepTwo} />
          </Route>
        </RegisterStoresProvider>
        {/* <Route path="/blog" exact component={UnavailableBlog} /> */}
        <Route path="*" exact component={Page404} />

      </Switch>

    </>

  );
}
