import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Image,
  Link,
  Tag,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MdOutlinePermMedia } from 'react-icons/md';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import api from '../../services/api';
import Indique from '../../assets/img/platform/navigation/indique.svg';
import ClassDocsIcon from '../../assets/img/platform/navigation/class-docs-icon.svg';
import ClassDocsIconBlue from '../../assets/img/platform/navigation/class-docs-icon-blue.svg';
import HomeIcon from '../../assets/img/platform/navigation/home-icon.svg';
import HomeIconBlue from '../../assets/img/platform/navigation/home-icon-blue.svg';
import MediaIcon from '../../assets/img/platform/navigation/media-icon.svg';
import MediaIconBlue from '../../assets/img/platform/navigation/media-icon-blue.svg';
import HistoryIcon from '../../assets/img/platform/navigation/history-icon.svg';
import HistoryIconBlue from '../../assets/img/platform/navigation/history-icon-blue.svg';
import TeacherIcon from '../../assets/img/platform/navigation/teacher-icon.svg';
import TeacherIconBlue from '../../assets/img/platform/navigation/teacher-icon-blue.svg';
import CreditCardIcon from '../../assets/img/platform/navigation/credit-card-icon.svg';
import CreditCardIconBlue from '../../assets/img/platform/navigation/credit-card-icon-blue.svg';
import ChatBubble from '../../assets/img/platform/navigation/chat-bubble.svg';
import ChatBubbleBlue from '../../assets/img/platform/navigation/basil_chat-outline.svg';
import LogoutIcon from '../../assets/img/platform/navigation/logout.svg';
import LogoutIconBlue from '../../assets/img/platform/navigation/logout-blue.svg';
import ConfigIconBlue from '../../assets/img/platform/navigation/config-icon-blue.svg';
import NotificationsIconBlue from '../../assets/img/platform/navigation/notifications-icon-blue.svg';
import { useStores } from '../../stores';
import NotificationBell from '../NotificationBell';
import DropdownNav from '../UserNavigation/DropdownNav';
import style from './style.module.scss';
import UnmazeLogo from '../../assets/img/unmaze/unmaze-logo.svg';
import notify from '../../utils/notify';
import CustomToastError from '../CustomToastError';

export default function NavbarStudentMobile() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  const location = useLocation();
  const {
    globalStatesStore: { setOpenSiteHomeModal },
    userStore: { userData, handleClearUserData, token },
    planUnpauseStore:
    {
      useCredits, setUseCredits,
      removeUseCredits,
    },
  } = useStores();

  const fullName = `${userData.firstName} ${userData.lastName}`;

  const [openPlanUnpause, setOpenPlanUnpause] = useState(false);
  const [openIsUnpausing, setOpenIsUnpausing] = useState(false);

  const {
    isPaused,
    isUnpausing,
    isPausingCredits,
  } = userData;

  const history = useHistory();

  function openUnpauseModal() {
    setUseCredits(false);
    setOpenPlanUnpause(true);
  }

  function handleLogout() {
    handleClearUserData();
    history.push('/');
  }

  async function recommendStudent() {
    try {
      const response = await api.post('/recommend', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status > 205) {
        notify(<CustomToastError
          toastText={response.data}
        />);
        return;
      }

      return window.open('https://unmazebr.wixsite.com/website-2', '_blank');
    } catch (error) {
      const { request } = error;

      if (request) {
        notify(<CustomToastError
          toastText={request.response}
        />);
        return;
      }

      return notify(<CustomToastError
        toastText={error.message}
      />);
    }
  }

  return (
    <>
      <HStack alignItems="center" w="100%" justifyContent="space-between">
        <Link href="/home/student">
          <Image src={UnmazeLogo} w="100px" />
        </Link>
        <Button
          ref={btnRef}
          onClick={onOpen}
          size="sm"
          variant="outline"
          colorScheme="#06097D"
        >
          {isOpen ? 'Fechar Menu' : 'Menu'}
        </Button>
      </HStack>
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerContent className={style['drawer-content']}>
          <DrawerHeader>
            <VStack alignItems="center" w="100%">
              <Avatar src={userData?.avatar} boxSize="52px" />
              <Heading as="h5" size="sm" color="#06097D">
                {fullName}
              </Heading>
            </VStack>
          </DrawerHeader>

          <DrawerBody className={style['drawer-body']}>
            <VStack className={style.navs}>
              <VStack w="100%" spacing={2}>
                <VStack w="100%" spacing={2} pl={5}>
                  <Divider w="85%" borderColor="#06097D" marginTop={3} />
                  <Text
                    alignSelf="start"
                    fontWeight="bold"
                    fontSize="16px"
                    ml={3}
                  >
                    Principal
                  </Text>

                  <NavLink
                    onClick={onClose}
                    to={`/home/${userData.userType.toLowerCase()}`}
                    className={style.a}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === `/home/${userData.userType.toLowerCase()}`
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === `/home/${userData.userType.toLowerCase()}`
                          ? HomeIcon : HomeIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        fontWeight={600}
                        color={location.pathname === `/home/${userData.userType.toLowerCase()}`
                          ? '#fff' : '#06097D'}
                      >
                        Início
                      </Text>
                    </HStack>
                  </NavLink>
                  {(userData.userType === 'Student' && userData?.classLink) && (
                  <Link
                    href={userData.classLink}
                    target="_blank"
                    rel="noreferrer"
                    w="100%"
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                    >
                      <Image src={ClassDocsIconBlue} boxSize="24px" />
                      <Text fontWeight={600}> Minhas aulas</Text>
                    </HStack>
                  </Link>
                  )}

                  <NavLink
                    onClick={onClose}
                    to="/platform/content-area"
                    style={{ width: '100%' }}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === '/platform/content-area'
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === '/platform/content-area'
                          ? MediaIcon : MediaIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        fontWeight={600}
                        color={location.pathname === '/platform/content-area'
                          ? '#fff' : '#06097D'}
                      >
                        Área de conteúdo
                      </Text>
                      {/* <Tag fontWeight={600} fontSize={['6px', '7px']}
                       bgColor="#ff1616" color="white">
                        novos
                      </Tag> */}
                    </HStack>
                  </NavLink>
                  <NavLink
                    onClick={onClose}
                    to="/history"
                    style={{ width: '100%' }}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === '/history'
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === '/history'
                          ? HistoryIcon : HistoryIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        fontWeight={600}
                        color={location.pathname === '/history'
                          ? '#fff' : '#06097D'}
                      >
                        Histórico
                      </Text>
                    </HStack>
                  </NavLink>
                  <NavLink
                    onClick={onClose}
                    to="/teacher-info"
                    style={{ width: '100%' }}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                      bg={location.pathname === '/teacher-info'
                        ? '#06097D' : '#fff'}
                    >
                      <Image
                        src={location.pathname === '/teacher-info'
                          ? TeacherIcon : TeacherIconBlue}
                        boxSize="24px"
                      />
                      <Text
                        fontWeight={600}
                        color={location.pathname === '/teacher-info'
                          ? '#fff' : '#06097D'}
                      >
                        Meu professor
                      </Text>
                    </HStack>
                  </NavLink>

                  <Link
                    target="_blank"
                    rel="noreferrer"
                    w="100%"
                    onClick={() => recommendStudent()}
                  >
                    <HStack
                      marginLeft="15px"
                      w="85%"
                      borderRadius={5}
                      spacing={2}
                      p={2}
                    >
                      <Image src={Indique} boxSize="24px" />
                      <Text> Indique e ganhe</Text>
                    </HStack>
                  </Link>

                </VStack>
              </VStack>
              <VStack w="100%" pl={5}>
                <Text
                  alignSelf="start"
                  ml={3}
                  mt={3}
                  fontWeight="bold"
                  fontSize="16px"
                >
                  Conta
                </Text>
                {(useCredits && isPaused) && (
                <div className={style['open-plan-modal']}>
                  <div className={style['button-container']}>
                    <button
                      className={style['open-modal-button']}
                      onClick={() => openUnpauseModal()}
                      type="button"
                    >
                      Reativar plano
                    </button>
                  </div>
                </div>
                )}
                {/* {userData.userType === 'Student' && (
              <Button
                className={style.button__green}
                color="white"
                backgroundColor="#47C13C"
                fontSize="sm"
                h="37px"
                marginBottom={5}
                value={!userData.plan ? 'subscribe' : 'renew'}
                onClick={(e) => handleAddHours(e.target.value)}
              >
                {!userData.plan ? 'Ver planos' : 'Solicitar renovação'}
              </Button>

            )} */}
                <NavLink
                  onClick={onClose}
                  to={`/profile/${userData.userType.toLowerCase()}`}
                  style={{ width: '100%' }}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                    bg={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                      ? '#06097D' : '#fff'}
                  >
                    <Image
                      src={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                        ? CreditCardIcon : CreditCardIconBlue}
                      boxSize="24px"
                    />
                    <Text
                      fontWeight={600}
                      color={location.pathname === `/profile/${userData.userType.toLowerCase()}`
                        ? '#fff' : '#06097D'}
                    >
                      Minha assinatura
                    </Text>
                  </HStack>
                </NavLink>
                <DropdownNav />
                <HStack
                  w="100%"
                  pl={2}
                >
                  <NotificationBell />
                </HStack>
                <Link
                  href="https://midnight-archduke-8c8.notion.site/D-vidas-frequentes-f318b33bfa2e4f82a062e91ad24e7b30"
                  target="_blank"
                  rel="noreferrer"
                  className={style.a}
                >
                  <HStack
                    marginLeft="15px"
                    w="85%"
                    borderRadius={5}
                    spacing={2}
                    p={2}
                  >
                    <Image src={ChatBubbleBlue} boxSize="24px" />
                    <Text
                      fontWeight={600}
                    >
                      Suporte
                    </Text>
                  </HStack>
                </Link>
                <HStack
                  as="button"
                  w="100%"
                  h="32px"
                  pl={2}
                  onClick={() => handleLogout()}
                >
                  <Image src={LogoutIconBlue} boxSize="24px" ml="15px" />
                  <Text
                    fontSize="14px"
                    fontWeight={600}
                    w="100%"
                    textAlign="start"
                  >
                    {userData.userType === 'Student' ? 'Sair' : 'Logout'}

                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
